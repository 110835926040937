import React, { Fragment } from "react"
import { graphql } from "gatsby"
import parse from "html-react-parser"
import { Heading } from "../components/styles/Sections"
import styled from "styled-components"
import { GatsbyImage } from "gatsby-plugin-image"
// We're using Gutenberg so we need the block styles
// these are copied into this project due to a conflict in the postCSS
// version used by the Gatsby and @wordpress packages that causes build
// failures.
// @todo update this once @wordpress upgrades their postcss version
import "../css/@wordpress/block-library/build-style/style.css"
import "../css/@wordpress/block-library/build-style/theme.css"

import Bio from "../components/bio"
import Layout from "../components/layout"
import Seo from "../components/seo"
import BreadCrumbs from "../components/breadcrumbs"
import { Button } from "../components/styles/Buttons"
import Footer from "../components/footer"
import Icon from "../components/Icon"
import ShareButtons from "../components/ShareButtons"

const BlogPostTemplate = ({ data: { article, relatedPosts }, location }) => {
  const ogImage = article.featuredImage.node.sourceUrl;
  const schema = article.seo ? article.seo.schema.raw : undefined;
  const seoTitle = article.seo ? article.seo.title : article.title;
  const canonicalUrl = article.seo ? "https://svenskaonlinecasinon.se" + article.seo.canonical : undefined;
  const seoMetaDescription = article.seo ? article.seo.metaDesc : undefined;
  const affiliateLink = article.campaign.campaignAffiliateTracker.link;
  let terms = article && article.campaign ? article.campaign.termsconditions : undefined;

  const heroImage = {
    data: article.campaign.heroImage?.localFile?.childImageSharp?.gatsbyImageData,
    alt: article.campaign.heroImage?.localFile?.altText || article.campaign.campaignBrand,
  }

  const crumbs = [ 
    {
    "text": "Hem",
    "url": "https://svenskaonlinecasinon.se"
    },
    {
      "text": "Kampanjer",
      "url": "/kampanjer"
    },
    {
      "text": article.campaign.campaignBrand,
      "url": "/kampanjer/" + article.slug
    }
  ]

  return (
    <Layout>
      <Seo 
        title={seoTitle} 
        description={seoMetaDescription}
        canonical={canonicalUrl} 
        modified={article.modified} 
        ogType={"article"}
        ogImage={ogImage}
        ogUrl={canonicalUrl} 
        schema={schema}
      />  
      <BreadCrumbs align="center" items={crumbs} modified={article.modified} />

      <CampaignHero>
        <div className="hero">
          {heroImage?.data && (
            <GatsbyImage
              image={heroImage.data}
              alt={heroImage.alt}
            />
          )}
          <div className="filter" style={{background: "linear-gradient(180deg, rgb(22 40 148 / 70%), #000)"}}></div>
        </div>
        <Heading>
          <header>
            <div className="info">
              <div><Icon icon="user1" size={13} /> {article.author.node.name}</div>  
              <div><Icon icon="calendar" size={12} /> {article.date}</div>
              <div><Icon icon="clock" size={12} /> {article.seo.readingTime} minuter</div> 
            </div>
            <h1 itemProp="headline">{parse(article.title)}</h1>
            {article.campaign && article.campaign.startDate && (
              <CampaignInfo>
                <p>Kampanjen gäller från: <strong>{article.campaign.startDate}</strong><strong> - {article.campaign.endDate}</strong></p>
              </CampaignInfo>
            )}
            <a href={affiliateLink} target="_blank" rel="nofollow noreferrer noopener">
              <Button className="green medium">Till Kampanjen</Button>
            </a>
            <div className="terms">        
              {terms ? (
                <div>{parse(terms)}</div>
                ) : ( <p>18+ | Spela ansvarfullt | <a href="https://www.stodlinjen.se" rel="nofollow noreferrer noopener" target="_blank">Stödlinjen.se</a> | <a href="https://www.spelpaus.se/" rel="nofollow noopener noreferrer" target="_blank">Spelpaus.se</a> | 
                  Regler och villkor gäller </p>
              )}
            </div>
          </header>
        </Heading>
      </CampaignHero>

      <article id="main-content" className="containerSmall">
        {!!article.content && (
          <Fragment>
            <section><h2>Kampanjdetaljer</h2>{parse(article.content)}</section>
            <ShareButtons url={canonicalUrl} />
            <Bio author={article.author.node} />
          </Fragment>
        )}
      </article>
      <Footer location={location} />
    </Layout>
  )
}

export default BlogPostTemplate

const CampaignHero = styled.div`
  background: rgb(18 42 159);
  padding: 60px 0 10px;
  color: #fff;
  position: relative;
  overflow: hidden;

  @media (max-width: 600px) {
    padding: 40px 15px 15px;

    a div {
      width: 100% !important;
      padding: 18px;  
    }
  }

  .hero {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 1;

    .gatsby-image-wrapper {
      height: 100%;
    }

    .filter {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      z-index: 2;
    }
  }

  header {
    max-width: 450px;
    margin: 0 auto;
    position: relative;
    z-index: 2;
  }

  h1, strong, svg {
    color: #fff !important;
  }

  h1 {
    font-size: 36px;
    line-height: 1.25;

    @media (max-width: 600px) {
      font-size: 30px;
    }
  }

  .info svg {
    margin: 0 8px;
  }

  .terms {
    color: #f5f5f5;
    font-size: 10px;
    margin-top: 25px;

    a {
      color: #f5f5f5;
    }
  }
`;

const CampaignInfo = styled.div`
  p {
    margin: 0 0 25px;
    padding: 8px 14px;
    border: 1px solid #ffffff80;
    border-radius: 12px;
    font-size: 14px;
    display: inline-block;

    strong {
      font-weight: 600;
    }
  }
`;

export const articleQuery = graphql`
  query CampignById(
    $id: String!
  ) {
    article: wpKampanj(id: { eq: $id }) {
      id
      content
      title
      slug
      author {
        node {
          name
          description
          uri
          avatar {
            url
          }
        }
      }
      campaign {
        campaignBrand
        brandBackgroundColor
        termsconditions
        heroImage {
          localFile {
            childImageSharp {
              gatsbyImageData (
                quality: 100
                layout: FULL_WIDTH
                placeholder: BLURRED
              )
            }
          }
          altText
        }
        startDate
        endDate
        
        campaignAffiliateTracker {
          ... on WpAffiliateTracker {
            id
            affiliateLinks {
              affiliateUrl
            }
            link
          }
        }
      }
      seo {
        title
        metaDesc 
        readingTime
        canonical
        breadcrumbs {
          url
          text
        }
        schema {
          raw
        }
        opengraphImage {
          link
        }
      }
      date(locale: "sv-SE", formatString: "MMMM DD, YYYY")
      modified(locale: "sv-SE", formatString: "MMMM DD, YYYY")
      featuredImage {
        node {
          altText
          sourceUrl
          localFile {
            childImageSharp {
              gatsbyImageData(
                quality: 100
                layout: FULL_WIDTH
                placeholder: BLURRED
              )
            }
          }
        }
      }
    }
  }
`
